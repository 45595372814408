<!--
MAP
-->
<template>
    <!--map-->
    <div class="container-fluid px-4 py-4 my-4 map">
      <div class="container">
        <div class="col-lg-3">
          <div class="map-title text-yellow fw-bold py-1"><font-awesome-icon :icon="['fas', 'chevron-circle-right']"/> {{$t('modules.address1')}}</div>
          <div class="map-list text-white px-4" v-html="$t('modules.maptext1')" />
          <div class="map-title text-yellow fw-bold py-1"><font-awesome-icon :icon="['fas', 'chevron-circle-right']"/> {{$t('modules.address2')}}</div>
          <div class="map-list text-white px-4" v-html="$t('modules.maptext2')" />
          <div class="map-title text-yellow fw-bold py-1"><font-awesome-icon :icon="['fas', 'chevron-circle-right']"/> {{$t('modules.address3')}}</div>
          <div class="map-list text-white px-4">
          </div>
        </div>
        </div>
    </div>
    <!--map-->
</template>

<script>
export default {
  name: "module11",
}
</script>

<style scoped>

</style>
